* {
  margin: 0;
  padding: 0;
  font-family: "Sofia Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

/*General Formatting*/
.clickable {
  cursor: pointer;
  transition: all 0.4s ease;
}
.clickable:hover {
  transition: all 0.4s ease;
  transform: scale(1.1);
  padding-left: 4px;
}

.clickable.underline {
  text-decoration: underline;
}

#facility-insights {
  background: #fafafa;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@media print {
  * {
    font-family: "Roboto Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif !important;
  }
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.flag-select {
  padding-bottom: 0px !important;
  margin-left: -10px !important;
}

#facility-insights .analytics_container {
  display: flex;
}

#facility-insights h1,
#facility-insights h2,
#facility-insights h3,
#facility-insights h4,
#facility-insights h5 {
  line-height: unset;
}

.MuiOutlinedInput-notchedOutline,
fieldset {
  background: transparent !important;
}

#facility-insights .analytics_container .underline {
  text-decoration: underline;
}
#facility-insights .analytics_container > div.main {
  flex: 0 1 80%;
  margin: 50px 80px;
  min-height: 87vh;
  box-sizing: border-box;
}

/*Media Queries*/
@media only screen and (max-width: 960px) {
  #facility-insights .analytics_container {
    display: block !important;
  }
  #facility-insights .analytics_container > div:first-child {
    margin-top: -75px;
  }
  #facility-insights .analytics_container > div.main {
    margin: 50px 20px;
  }
}
@media only screen and (max-width: 600px) {
  #facility-insights .analytics_container > div:first-child {
    margin-top: -27px;
    background: #fff;
    height: 40px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.03);
  }
}

/*Color styles*/
.success {
  color: #027a48;
  background-color: rgba(63, 218, 152, 0.1);
}
.danger {
  color: #ed6e7e;
  background-color: rgba(218, 63, 63, 0.1);
}
.warning {
  color: #bc5317;
  background: rgba(254, 161, 45, 0.1);
}
.default {
  color: #667898;
  background: #eaecf1;
}
/*Toasts*/
.Toastify__toast-icon {
  width: 50px;
}
.Toastify__toast-theme--light > div {
  color: #384853;
}
.Toastify__toast-container {
  width: 97%;
  max-width: 433px;
}
.Toastify__toast {
  box-shadow: unset;
  border: 1px solid;
  border-left: 10px solid;
  border-radius: 9px;
}
.Toastify__toast-body > div:last-child {
  font-size: 14px;
  padding: 0px 5px;
  line-height: 1.6em;
}

.Toastify__toast--error {
  border-color: #ed6e7e;
}

.Toastify__toast--success {
  border-color: #3fda98;
}

.Toastify__toast--info {
  border-color: #3399cc;
}

.Toastify__progress-bar {
  left: 15px;
  height: 2px;
}
