div.google-visualization-tooltip {
  background-color: #262626;
  color: #fff;
  border: 0;
  box-shadow: 0;
  border-radius: 4px;
  padding: 7px 10px;
  margin-left: -20px;
  margin-top: -20px;
  pointer-events: none;
}
